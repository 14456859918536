





































import { Vue, Component, Ref } from 'vue-property-decorator';
import dayjs from 'dayjs';
import BaseList from '@/components/Base/BaseList/index.vue';
import CreditListItem from './comm/CreditListItem.vue';
import { getCreditList, getStatistics } from '@/api/credit';
import { GetPromiseReturn } from '@/api/type/tool';
import { MerchantModule } from '@/store/modules/Merchant';
@Component({
  name: '',
  components: { BaseList, CreditListItem }
})
export default class extends Vue {
  /* 时间选择类 */
  pickerTimeShow = false;
  currentDate = new Date();
  minDate = dayjs().subtract(1, 'year').toDate();
  maxDate = new Date();
  /* 格式化picker */
  formatter(type: string, val: string) {
    if (type === 'year') {
      return `${val}年`;
    } else if (type === 'month') {
      return `${val}月`;
    }
    return val;
  }
  /* 选择时间确认 */
  onPickerTimeConfirm(e: Date) {
    this.currentDate = e;
    this.pickerTimeShow = false;
    this.myList.reload();
    this.getStatistics();
  }

  @Ref('myList')
  myList!: BaseList;

  get api() {
    return getCreditList;
  }
  get param() {
    const curTime = dayjs(this.currentDate);
    return {
      pointId: MerchantModule.payPointId,
      startDate: curTime.startOf('month').format('YYYY-MM-DD HH:mm:ss'),
      endDate: curTime.endOf('month').format('YYYY-MM-DD HH:mm:ss')
    };
  }

  creditStatistics = {} as GetPromiseReturn<typeof getStatistics>['payload'];
  async getStatistics() {
    const { payload } = await getStatistics(this.param);
    this.creditStatistics = payload;
  }

  created() {
    this.getStatistics();
  }
}
